import 'current-script-polyfill';
import 'url-search-params-polyfill';
import 'whatwg-fetch';
import {
  EnvironmentTier,
  getEnvironmentTier,
} from './nav/utils/getEnvironmentTier';

// https://stackoverflow.com/a/38769139
const { currentScript } = document;

const params = new URLSearchParams(window.location.search);

const redirectURL = 'https://cdn.zuora.com/@platform-ui/redirects/main.js';
const redirectSelector = `script[src="${redirectURL}"]`;
const isRedirectScriptMissing = !document.querySelector(redirectSelector);

// Add redirect script at runtime if necessary
if (isRedirectScriptMissing) {
  const redirectScript = document.createElement('script');
  // https://web.dev/speed-script-loading/#the-dom-to-the-rescue
  redirectScript.async = false;
  redirectScript.crossOrigin = 'anonymous';
  redirectScript.src = redirectURL;
  currentScript?.after(redirectScript);
}

const script = document.createElement('script');
script.crossOrigin = 'anonymous';
const isDebugMode = params.get('enableZUnifiedNavDebug') === 'true';

const testDriveURL = window.localStorage.getItem('ZNavTestDriveURL');

/**
 * Fetch configuration file from CDN to set which version of nav-v2 script to load
 */
const fetchScriptSrc = async (env: EnvironmentTier, isDebugMode = false) => {
  const suffix = isDebugMode ? '.debug' : '';
  const envName = EnvironmentTier[env];

  return fetch(
    `https://cdn.zuora.com/@platform-ui/nav-v2/versions/${envName.toLowerCase()}.json`,
  )
    .then((res) => res.json())
    .then(({ version }: { version: string }) => {
      console.log(
        `[nav-v2] Using version ${version} for environment ${envName}`,
      );
      return `https://cdn.zuora.com/@platform-ui/nav-v2/${version}/index${suffix}.js`;
    })
    .catch((err) => {
      console.error(
        '[nav-v2] Unable to fetch version configuration from CDN, defaulting to latest',
        err,
      );
      return `https://cdn.zuora.com/@platform-ui/nav-v2/latest/index${suffix}.js`;
    });
};

if (testDriveURL) {
  const { hostname } = new URL(testDriveURL);
  if (hostname === 'cdn.zuora.com') {
    script.src = testDriveURL;
  }
} else {
  const env = getEnvironmentTier();
  fetchScriptSrc(env, isDebugMode).then((url) => {
    script.src = url;
  });
}

currentScript?.after(script);
