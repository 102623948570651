import isChromatic from 'chromatic';

export enum EnvironmentTier {
  DEV,
  STG,
  SBX,
  PROD,
}

const devHosts = [
  'host.docker.internal',
  'cdn.zuora.com',
  'localhost',
  'staging2-demo.zuora.com',
];

const stgHosts = [
  'one.stg.na.zuora.com',
  'staging2.zuora.com',
  'preprod.zuora.com',
];

const sbxHosts = [
  'apisandbox.zuora.com',
  'sandbox.na.zuora.com',
  'sandbox.eu.zuora.com',
  'test.zuora.com',
  'test.ap.zuora.com',
  'test.eu.zuora.com',
  'one-sandbox.zuora.com',
  'sandbox.ap.zuora.com',
];

export const getEnvironmentTier = (
  hostname = window.location.hostname,
): EnvironmentTier => {
  const isHostMatch = (hostnames: string[]) => hostnames.includes(hostname);

  const isEnverIP = hostname.startsWith('10.252');
  const isEnverHostname = hostname.includes('dev.na2.zuora.com');

  // chromatic hostnames are randomly generated
  if (isHostMatch(devHosts) || isChromatic() || isEnverIP || isEnverHostname) {
    return EnvironmentTier.DEV;
  }

  if (isHostMatch(stgHosts)) {
    return EnvironmentTier.STG;
  }

  if (isHostMatch(sbxHosts)) {
    return EnvironmentTier.SBX;
  }

  // default to production for unknown hosts
  return EnvironmentTier.PROD;
};
